import {FC} from "react";
import {isFinishedState, useAppContext} from "../../../components/appContext/AppContextProvider.tsx";
import {Navigate, Outlet} from "react-router-dom";

export const AppSetupLayout: FC = () => {
    const state = useAppContext();

    if (isFinishedState(state)) {
        return <Navigate to="/" replace />
    }
    return <>
        <Outlet />
    </>
}