import {
    AuthProvider,
    InitializationHandler,
    LoginHandler,
    LogoutHandler, PasswordResetHandler,
    RefreshHandler,
    RequestPasswordResetHandler
} from "./AuthProvider.tsx";
import {FC, ReactNode, useCallback} from "react";
import {api} from "../../api/api.ts";
import { AxiosResponse} from "axios";

const acceptHeader = {}
const getErrorResultFromResponse = (res: AxiosResponse, title: string | undefined) => {
    return {errors: res.data ? [res.data as string] : [title as string]}
}

const ACCESS_TOKEN_LIFE_TIME_COOKIE_NAME = "accessToken";
//const REFRESH_TOKEN_LIFE_TIME_COOKIE_NAME = "refreshToken";
const noErrorResult = {errors: [] as string[]}
export const CookieAuthProvider: FC<{children: ReactNode}> = ({children}) => {
    const authUrl = "/api/admin"
    const emailRedirectBaseUrl = "/api/admin";
    const handleLogin = useCallback<LoginHandler>(
        async (credentials) => {
            try {
                const response = await api.apiAdminAuthLoginPost(credentials, {
                    withCredentials: true,
                    headers: acceptHeader,
                });
                return response.status === 204 ? noErrorResult : await getErrorResultFromResponse(response, "Unknown server error");
            } catch (e) {
                return getErrorResultFromResponse(e as AxiosResponse, "Unknown server error");
            }

        },
        [authUrl],
    );

    const handleRefresh = useCallback<RefreshHandler>(async () => {
        try {
            const response = await api.apiAdminAuthRefreshGet({
                withCredentials: true,
                headers: acceptHeader,
            });
            return response.status === 204 ? noErrorResult : await getErrorResultFromResponse(response, "Unknown server error");
        } catch (e) {
            return getErrorResultFromResponse(e as AxiosResponse, "Unknown server error");
        }
    }, [authUrl]);

    const handleLogout = useCallback<LogoutHandler>(async () => {
        const response = await api.apiAdminAuthLogoutGet({
            withCredentials: true,
            headers: acceptHeader,
        });

        return response.status === 204 ? noErrorResult : await getErrorResultFromResponse(response, "Unknown server error");
    }, [authUrl]);

    const handleInitialization = useCallback<InitializationHandler>(async () => {
        if (document.cookie.includes(ACCESS_TOKEN_LIFE_TIME_COOKIE_NAME)) {
            return { loggedIn: true };
        }

        if (!document.cookie.includes(ACCESS_TOKEN_LIFE_TIME_COOKIE_NAME)) {
            const res = await handleRefresh();

            if (res.errors.length > 0) {
                return { loggedIn: false };
            }

            return { loggedIn: true };
        }

        return { loggedIn: false };
    }, [handleRefresh]);

    const handleRequestPasswordReset = useCallback<RequestPasswordResetHandler>(
        async () => {
            return noErrorResult;
            /*
            const response = await fetch(`${authUrl}/send-password-reset-link`, {
                method: "POST",
                body: JSON.stringify({
                    ...value,
                    redirectUrl: `${emailRedirectBaseUrl}/reset-password`,
                }),
                headers: contentTypeHeaders,
                credentials: "include",
            });

            if (response.ok) {
                return noErrorResult;
            }

            return response.ok
                ? noErrorResult
                : await getErrorResultFromResponse(response, "An error occurred during the password reset request");*/
        },
        [authUrl, emailRedirectBaseUrl],
    );

    const handlePasswordReset = useCallback<PasswordResetHandler>(
        async () => {
            return noErrorResult;
           /* const response = await fetch(`${authUrl}/reset-password`, {
                method: "POST",
                body: JSON.stringify(passwordReset),
                headers: contentTypeHeaders,
                credentials: "include",
            });

            return response.ok
                ? noErrorResult
                : await getErrorResultFromResponse(response, "An error occurred during the password reset");*/
        },
        [authUrl],
    );

    return (
        <AuthProvider
            handleLogin={handleLogin}
            handleRefresh={handleRefresh}
            handleLogout={handleLogout}
            handleInitialization={handleInitialization}
            handleRequestPasswordReset={handleRequestPasswordReset}
            handlePasswordReset={handlePasswordReset}
        >
            {(isInitialized) => isInitialized && children}
        </AuthProvider>
    );
}