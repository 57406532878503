import {FC, ReactNode} from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {LoginPage} from "./login/LoginPage.tsx";
import {RegisterPage} from "./register/RegisterPage.tsx";
import {ForgotPasswordPage} from "./forgot-password/ForgotPasswordPage.tsx";
import {Protected} from "../components/Protected.tsx";
import {
    AppContextProvider, AppSetupState, isChooseProductsState,
    isDefineContractorState, isFinishedState, isLoadingState,
    useAppContext
} from "../components/appContext/AppContextProvider.tsx";
import {Layout} from "../components/Layout.tsx";
import {AppSetupLayout} from "./setup/layout/AppSetupLayout.tsx";
import {DefineContractorPage} from "./setup/defineContractor/DefineContractorPage.tsx";
import {ChooseProductsPage} from "./setup/chooseProducts/ChooseProductsPage.tsx";
import {PortalRegistrationPage} from "./admin/portalRegistration/PortalRegistrationPage.tsx";

export const AppRoutes: FC = () => {
    return (
        <Routes>
            <Route element={<Protected redirectUrl="/login">
                <AppContextProvider>
                    <AppLayout />
                </AppContextProvider>
            </Protected>}>
                <Route index element={<HomePage />} />
                <Route element={<AppSetupLayout />}>
                    <Route path="setup">
                        <Route index element={<SetupRedirect />} />
                        <Route path="choose-products" element={<SetupStepProtection checkFn={isChooseProductsState}>
                            <ChooseProductsPage />
                        </SetupStepProtection>} />
                        <Route path="define-contractor" element={<SetupStepProtection checkFn={isDefineContractorState}>
                            <DefineContractorPage />
                        </SetupStepProtection>} />
                    </Route>
                </Route>
                <Route path="admin" element={<SetupStepProtection checkFn={isFinishedState}><Layout /></SetupStepProtection>}>
                    <Route path="users" element={<div>Users</div>} />
                    <Route path="portal-registration" element={<PortalRegistrationPage />} />
                    <Route path="settings" element={<div>Settings</div>} />
                </Route>
            </Route>
            <Route>
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
            </Route>
        </Routes>
    )
}
export const AppLayout: FC = () => {
    const state = useAppContext();
    
    if (isLoadingState(state)) {
        return <div>Lade...</div>
    }
    
    return <Outlet />
}
export const HomePage: FC = () => {
    const state = useAppContext();
    
    if (!isFinishedState(state)) {
        return <Navigate to="/setup" replace />
    }
    
    return <Navigate to="admin" replace />
}
export const SetupRedirect: FC = () => {
    const state = useAppContext();
    
    if (isFinishedState(state)) {
        return <Navigate to=".." replace />
    }
    if (isChooseProductsState(state)) {
        return <Navigate to="choose-products" replace />
    }
    if (isDefineContractorState(state)) {
        return <Navigate to="define-contractor" />
    }
    return null;
}

interface Props {
    children: ReactNode
    checkFn: (state: AppSetupState) => boolean
}
export const SetupStepProtection: FC<Props> = ({checkFn, children}) => {
    const state = useAppContext();
    
    if (!checkFn(state)) {
        return <Navigate to="/setup" replace />
    }
    
    return <>{children}</>
}
