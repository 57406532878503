import {FC} from "react";
import {ProfileDropdown} from "./ProfileDropdown.tsx";

export const AppBar: FC = () => {
    return <header className="bg-white w-full shadow-md z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-4">
            <h1 className="text-xl font-semibold text-gray-800">AppName</h1>
            <ProfileDropdown />
        </div>
    </header>
    /*
    return (
        <div className="w-full h-16 bg-gray-800 shadow flex items-center justify-between px-6">
            <div className="font-semibold text-lg">Admin Dashboard</div>
            <div className="flex items-center space-x-4">
                <button className="p-2 rounded-lg hover:bg-gray-200">🔔</button>
                <img
                    src="https://via.placeholder.com/32"
                    alt="User Avatar"
                    className="h-8 w-8 rounded-full"
                />
            </div>
        </div>
    );*/
}
