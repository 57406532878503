import {Sidebar} from './Sidebar';
import {AppBar} from './AppBar';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 flex flex-col">
                <AppBar />
                <main className="flex-1 bg-gray-50 p-4">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

