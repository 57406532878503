import {FC, ReactElement} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "./auth/AuthProvider.tsx";
import {AuthPhase} from "./auth/authTypes.ts";

export interface ProtectedProps {
    redirectUrl?: string;
    redirectBack?: boolean;
    fallback?: ReactElement;
    children: ReactElement;
}

export const Protected: FC<ProtectedProps> = ({
                                                  children,
                                                  fallback,
                                                  redirectUrl = "/login",
                                                  redirectBack = false,
                                              }) => {
    const {
        state: {phase},
    } = useAuth();
    const {pathname} = useLocation();
    if (phase !== AuthPhase.LOGGED_IN && phase !== AuthPhase.REFRESHING) {
        return fallback ?? <Navigate to={redirectBack ? `${redirectUrl}?redirect=${pathname}` : redirectUrl}/>;
    }

    return <>{children}</>;
};
