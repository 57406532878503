import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { AuthReducerState, authReducer, authReducerInitialState } from "./authReducer";
import { AuthPhase, PasswordReset, RequestPasswordReset } from "./authTypes";
import {useApiInterceptor} from "../../api/api";

export interface LoginCredentials {
    email: string;
    password: string;
}
interface AuthState {
    state: AuthReducerState;
    login: (credentials: LoginCredentials) => Promise<void>;
    refresh: () => Promise<void>;
    logout: () => Promise<void>;
    resetPassword: (passwordReset: PasswordReset) => Promise<void>;
    requestPasswordReset: (value: RequestPasswordReset) => Promise<void>;
    isLoggedIn: boolean;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export interface HandlerResult {
    errors: string[];
}

export type LoginHandler = (credentials: LoginCredentials) => Promise<HandlerResult>;
export type RefreshHandler = () => Promise<HandlerResult>;
export type LogoutHandler = () => Promise<HandlerResult>;
export type PasswordResetHandler = (passwordReset: PasswordReset) => Promise<HandlerResult>;
export type RequestPasswordResetHandler = (value: RequestPasswordReset) => Promise<HandlerResult>;

export type InitializationHandler = () => Promise<{ loggedIn: boolean }>;

interface Props {
    handleLogin: LoginHandler;
    handleInitialization: InitializationHandler;
    handleRefresh: RefreshHandler;
    handleLogout: LogoutHandler;
    handlePasswordReset: PasswordResetHandler;
    handleRequestPasswordReset: RequestPasswordResetHandler;
    children: (isInitialized: boolean) => ReactNode;
}

export const RefreshInterceptor = () => {
    useApiInterceptor();
    return null;
}
export const AuthProvider: FC<Props> = ({
                                            children,
                                            handleRefresh,
                                            handleLogin,
                                            handleLogout,
                                            handleInitialization,
                                            handlePasswordReset,
                                            handleRequestPasswordReset,
                                        }) => {
    const [state, dispatch] = useReducer(authReducer, authReducerInitialState);
    const allowInitializeRef = useRef(true);
    const allowRefreshRef = useRef(true);

    const login = useCallback(
        async (credentials: LoginCredentials) => {
            dispatch({ type: "LOGIN" });
            const res = await handleLogin(credentials);

            if (res.errors.length > 0) {
                return dispatch({ type: "LOGIN_FAILED", errors: res.errors });
            }
            dispatch({ type: "LOGIN_SUCCESS" });
        },
        [handleLogin],
    );

    const refresh = useCallback(async () => {
        if (allowRefreshRef.current) {
            allowRefreshRef.current = false;
            dispatch({ type: "REFRESH" });
            const res = await handleRefresh();

            if (res.errors.length > 0) {
                dispatch({ type: "REFRESH_FAILED", errors: res.errors });
                return void 0;
            }

            dispatch({ type: "REFRESH_SUCCESS" });
            allowRefreshRef.current = true;
        }
    }, [handleRefresh]);

    const logout = useCallback(async () => {
        dispatch({ type: "LOGOUT" });
        await handleLogout();

        dispatch({ type: "LOGOUT_SUCCESS" });
    }, [handleLogout]);

    const resetPassword = useCallback(
        async (passwordReset: PasswordReset) => {
            dispatch({ type: "PASSWORD_RESET" });
            const res = await handlePasswordReset(passwordReset);

            if (res.errors.length > 0) {
                return dispatch({ type: "PASSWORD_RESET_FAILED", errors: res.errors });
            }

            dispatch({ type: "PASSWORD_RESET_SUCCESS" });
        },
        [handlePasswordReset],
    );

    const requestPasswordReset = useCallback(
        async (value: RequestPasswordReset) => {
            dispatch({ type: "REQUEST_PASSWORD_RESET" });
            const res = await handleRequestPasswordReset(value);

            if (res.errors.length > 0) {
                return dispatch({ type: "REQUEST_PASSWORD_RESET_FAILED", errors: res.errors });
            }

            dispatch({ type: "REQUEST_PASSWORD_RESET_SUCCESS" });
        },
        [handleRequestPasswordReset],
    );

    const initialize = useCallback(async () => {
        if (allowInitializeRef.current) {
            allowInitializeRef.current = false;
            dispatch({ type: "INITIALIZE" });
            const res = await handleInitialization();

            if (!res.loggedIn) {
                return dispatch({ type: "LOGOUT_SUCCESS" });
            }

            dispatch({ type: "LOGIN_SUCCESS" });
            allowInitializeRef.current = true;
        }
    }, [handleInitialization]);

    useEffect(() => {
        void initialize();
    }, [initialize]);

    return (
        <AuthContext.Provider
            value={{
                state,
                login,
                refresh,
                logout,
                resetPassword,
                requestPasswordReset,
                isLoggedIn: state.phase === AuthPhase.LOGGED_IN,
            }}
        >
            {children(state.phase !== AuthPhase.UNKNOWN && state.phase !== AuthPhase.INITIALIZING)}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const ctx = useContext(AuthContext);

    if (ctx === undefined) {
        throw new Error("useAuth needs to be wrapped inside a AuthProvider.");
    }

    return ctx;
};
