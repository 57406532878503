export enum AuthPhase {
    UNKNOWN = "UNKNOWN",
    LOGGING_IN = "LOGGING_IN",
    LOGGED_IN = "LOGGED_IN",
    LOGIN_FAILED = "LOGIN_FAILED",
    REFRESHING = "REFRESHING",
    REFRESH_FAILED = "REFRESH_FAILED",
    LOGGING_OUT = "LOGGING_OUT",
    LOGGED_OUT = "LOGGED_OUT",
    RESETTING_PASSWORD = "RESETTING_PASSWORD",
    RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED",
    REQUESTING_PASSWORD_RESET = "REQUESTING_PASSWORD_RESET",
    REQUEST_PASSWORD_RESET_FAILED = "REQUEST_PASSWORD_RESET_FAILED",
    REQUEST_PASSWORD_RESET_SUCCEEDED = "REQUEST_PASSWORD_RESET_SUCCEEDED",
    INITIALIZING = "INITIALIZING",
}

export interface RequestPasswordReset {
    email: string;
}

export interface PasswordReset {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
}
