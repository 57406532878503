import { AuthPhase } from "./authTypes";

const noErrors: string[] = [];

interface InitializeAction {
    type: "INITIALIZE";
}

interface LoginAction {
    type: "LOGIN";
}

interface LoginSuccessAction {
    type: "LOGIN_SUCCESS";
}

interface LoginFailedAction {
    type: "LOGIN_FAILED";
    errors: string[];
}

interface RefreshAction {
    type: "REFRESH";
}

interface RefreshSuccessAction {
    type: "REFRESH_SUCCESS";
}

interface RefreshFailedAction {
    type: "REFRESH_FAILED";
    errors: string[];
}

interface LogoutAction {
    type: "LOGOUT";
}

interface LogoutSuccessAction {
    type: "LOGOUT_SUCCESS";
}

interface RequestPasswordResetAction {
    type: "REQUEST_PASSWORD_RESET";
}

interface RequestPasswordResetSuccessAction {
    type: "REQUEST_PASSWORD_RESET_SUCCESS";
}

interface RequestPasswordResetFailedAction {
    type: "REQUEST_PASSWORD_RESET_FAILED";
    errors: string[];
}

interface PasswordResetAction {
    type: "PASSWORD_RESET";
}

interface PasswordResetSuccessAction {
    type: "PASSWORD_RESET_SUCCESS";
}

interface PasswordResetFailedAction {
    type: "PASSWORD_RESET_FAILED";
    errors: string[];
}

type AuthReducerAction =
    | InitializeAction
    | LoginAction
    | LoginFailedAction
    | LoginSuccessAction
    | LogoutAction
    | LogoutSuccessAction
    | PasswordResetAction
    | PasswordResetFailedAction
    | PasswordResetSuccessAction
    | RefreshAction
    | RefreshFailedAction
    | RefreshSuccessAction
    | RequestPasswordResetAction
    | RequestPasswordResetFailedAction
    | RequestPasswordResetSuccessAction;

export interface AuthReducerState {
    phase: AuthPhase;
    errors: string[];
    previousPhase: AuthPhase;
}

export const authReducerInitialState: AuthReducerState = {
    phase: AuthPhase.UNKNOWN,
    errors: noErrors,
    previousPhase: AuthPhase.UNKNOWN,
};

export function authReducer(state: AuthReducerState, action: AuthReducerAction): AuthReducerState {
    const createState = (phase: AuthPhase, errors: string[] = []): AuthReducerState => {
        return {
            ...state,
            errors,
            previousPhase: state.phase,
            phase,
        };
    };

    switch (action.type) {
        case "INITIALIZE":
            return createState(AuthPhase.INITIALIZING);
        case "LOGIN":
            return createState(AuthPhase.LOGGING_IN);
        case "LOGIN_SUCCESS":
            return createState(AuthPhase.LOGGED_IN);
        case "LOGIN_FAILED":
            return createState(AuthPhase.LOGIN_FAILED, action.errors);
        case "REFRESH":
            return createState(AuthPhase.REFRESHING);
        case "REFRESH_SUCCESS":
            return createState(AuthPhase.LOGGED_IN);
        case "REFRESH_FAILED":
            return createState(AuthPhase.REFRESH_FAILED, action.errors);
        case "LOGOUT":
            return createState(AuthPhase.LOGGING_OUT);
        case "LOGOUT_SUCCESS":
            return createState(AuthPhase.LOGGED_OUT);
        case "PASSWORD_RESET":
            return createState(AuthPhase.RESETTING_PASSWORD);
        case "PASSWORD_RESET_FAILED":
            return createState(AuthPhase.RESET_PASSWORD_FAILED, action.errors);
        case "PASSWORD_RESET_SUCCESS":
            return createState(AuthPhase.LOGGED_IN);
        case "REQUEST_PASSWORD_RESET":
            return createState(AuthPhase.REQUESTING_PASSWORD_RESET);
        case "REQUEST_PASSWORD_RESET_FAILED":
            return createState(AuthPhase.REQUEST_PASSWORD_RESET_FAILED, action.errors);
        case "REQUEST_PASSWORD_RESET_SUCCESS":
            return createState(AuthPhase.REQUEST_PASSWORD_RESET_SUCCEEDED);
    }
}
