import {FC} from "react";
import {Form, Formik} from "formik";
import {TextField} from "../../../components/form/TextField.tsx";
import {api} from "../../../api/api.ts";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../../components/toast/hooks.ts";
import {appContextKey} from "../../../components/appContext/AppContextProvider.tsx";
import {useQueryClient} from "@tanstack/react-query";
import {SubmitButton} from "digitallife-react";

interface FormState {
    name: string
}
export const DefineContractorPage: FC = () => {
    const navigate = useNavigate();
    const {toast} = useToast()
    const queryClient = useQueryClient();
    
    const onSubmit = async (values: FormState) => {
        const res = await api.defineContractor(values);
        if (res.status === 201) {
            toast({
                text: "Gespeichert",
                color: "green",
            });
            await queryClient.invalidateQueries({
                queryKey: appContextKey,
            });
            navigate("../choose-products")
        } else {
            toast({
                text: "Dienstleister konnte nicht gespeichert werden",
                color: "white",
                background: "red"
            });
        }
    }
    return <>
        <h1>Dienstleister anlegen</h1>
        <h4>Gib einen Namen als Dienstleister an:</h4>
        <Formik initialValues={{name: ""}} onSubmit={onSubmit}>
            <Form>
                <TextField name="name" label="Name" />
                <SubmitButton>Speichern</SubmitButton>
            </Form>
        </Formik>
    </>
}