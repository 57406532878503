import {Form, Formik} from "formik";

interface FormState {
    email: string,
    password: string,
    repeatPassword: string
}

export const ForgotPasswordPage = () => {
    const onSubmit = (form: FormState) => {
        console.log(form);
    }
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-sm w-full">
                <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Forgot Password</h2>
                <Formik initialValues={{email: "", password: "", repeatPassword: ""}} onSubmit={onSubmit}>
                    <Form className="space-y-6">
                        <div>
                            <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                placeholder="you@example.com"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white font-medium py-2 rounded-lg hover:bg-blue-700 transition-colors duration-200"
                        >
                            Send
                        </button>
                        <div className="mt-6 text-center">
                            <a href="/Login" className="text-blue-600 hover:underline text-sm">
                                Login
                            </a>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}