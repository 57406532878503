import {BrowserRouter} from "react-router-dom";
import {AppRoutes} from "./pages/AppRoutes.tsx";
import {CookieAuthProvider} from "./components/auth/CookieAuthProvider.tsx";
import {ToastProvider} from "./components/toast/ToastProvider.tsx";
import {RefreshInterceptor} from "./components/auth/AuthProvider.tsx";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { retry: false },
    },
});

function App() {
    return (
        <BrowserRouter
            future={{
                v7_relativeSplatPath: true,
                v7_startTransition: true,
            }}
        >
            <ToastProvider>
                <QueryClientProvider client={queryClient}>
                    <CookieAuthProvider>
                        <RefreshInterceptor />
                        <AppRoutes />
                    </CookieAuthProvider>
                </QueryClientProvider>
            </ToastProvider>
        </BrowserRouter>
    )
}

export default App
