import {Form, Formik} from "formik";
import {LoginCredentials, useAuth} from "../../components/auth/AuthProvider.tsx";
import {useNavigate} from "react-router-dom";
import {TextField} from "../../components/form/TextField.tsx";
import {useEffect} from "react";
import {SubmitButton} from "digitallife-react";


export const LoginPage = () => {
    const {login, state} = useAuth();
    const navigate = useNavigate();
    const handleLogin = async (values: LoginCredentials) => {
        await login(values);

        navigate("/");
    };

    const handleProviderLogin = (provider: string) => {
        // Handle third-party provider login here
        console.log(`Logging in with ${provider}`);
    };

    useEffect(() => {
        if (state.phase === "LOGGED_IN") {
            navigate("/");
        }
    }, [state.phase]);
    
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
                    Willkommen zurück
                </h2>
                <p className="text-center text-gray-500 mb-6">
                    Melde dich an, um fortzufahren
                </p>
                {state.errors.length > 0 && <p className="text-red-500 mb-2 text-center">Login fehlgeschlagen</p>}
                <Formik initialValues={{email: "", password: ""}} onSubmit={handleLogin} className="space-y-4">
                    <Form>
                        <TextField name="email" label={"E-Mail Adresse"} placeholder="max@mustermann.de" required />
                        <TextField name="password" label={"Password"} type="password" required />
                        
                        <SubmitButton className="w-full">Einloggen</SubmitButton>                        
                    </Form>
                </Formik>

                {/* Alternative Login */}
                <div className="text-center text-sm text-gray-500 mt-4">Oder</div>
                <div className="flex space-x-4 mt-4">
                    <button
                        onClick={() => handleProviderLogin('Google')}
                        className="flex-1 text-gray-700 bg-gray-100 py-2 rounded-lg text-sm font-medium hover:bg-gray-200"
                    >
                        Mit Google einloggen
                    </button>
                    <button
                        onClick={() => handleProviderLogin('GitHub')}
                        className="flex-1 text-gray-700 bg-gray-100 py-2 rounded-lg text-sm font-medium hover:bg-gray-200"
                    >
                        Mit GitHub einloggen
                    </button>
                </div>

                {/* Links */}
                <div className="mt-6 text-center">
                    <a href="/forgot-password" className="text-blue-600 hover:underline text-sm">
                        Passwort vergessen?
                    </a>
                </div>
                <div className="mt-2 text-center">
                    <span className="text-gray-500 text-sm">Noch keinen Account? </span>
                    <a href="/register" className="text-blue-600 hover:underline text-sm">
                        Jetzt registrieren
                    </a>
                </div>
            </div>
        </div>
    )
}