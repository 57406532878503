import {Form, Formik} from "formik";
import {api} from "../../api/api.ts";
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import {TextField} from "../../components/form/TextField.tsx";
import {InferType} from "yup";
import {SubmitButton} from "digitallife-react";

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Required'),
    lastName: Yup.string()
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(6).oneOf([Yup.ref('confirmPassword')], "Passwords don't match").required('Required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match").required('Required'),
});

type FormState = InferType<typeof validationSchema>;
const initialValues: FormState = {
    firstName: "",
    lastName: "",
    email: "", 
    password: "", 
    confirmPassword: ""
}

export const RegisterPage = () => {
    const navigate = useNavigate();
    const onSubmit = async ({confirmPassword, ...form}: FormState) => {
        const res = await api.register(form);

        if (res.status === 201) {
            navigate("/login");
        }
    }
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-sm w-full">
                <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Register</h2>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    <Form className="space-y-6">
                        <TextField name={"firstName"} label={"Vorname"} placeholder="Max" required />
                        <TextField name={"lastName"} label={"Nachname"} placeholder="Mustermann" required />
                        <TextField name={"email"} label={"E-Mail"} placeholder="max@mustermann.de" required />
                        <TextField name={"password"} label={"Passwort"} type={"password"} required />
                        <TextField name={"confirmPassword"} label={"Passwort wiederholen"} type={"password"} required />
                        <SubmitButton>Registrieren</SubmitButton>
                        <div className="mt-6 text-center">
                            <a href="/Login" className="text-blue-600 hover:underline text-sm">
                                Login
                            </a>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

