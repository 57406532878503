import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useToast} from "../../../components/toast/hooks.ts";
import {api} from "../../../api/api.ts";
import {Form, Formik} from "formik";
import {CheckboxField} from "../../../components/form/CheckboxField.tsx";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {appContextKey} from "../../../components/appContext/AppContextProvider.tsx";
import {SubmitButton} from "digitallife-react";
import {Loading} from "../../../components/Loading.tsx";
import {ProductType} from "../../../api/api_gen";



type FormState = Record<ProductType, boolean>
export const ChooseProductsPage: FC = () => {
    const navigate = useNavigate();
    const {toast} = useToast()
    const queryClient = useQueryClient();
    const {data} = useQuery({
        queryKey: ["products"],
        queryFn: () => api.listProducts().then(x => x.data.data)
    }) 
    const onSubmit = async (values: FormState) => {
        const res = await api.chooseProducts({
            products: Object.keys(values).filter(x => values[x as ProductType]) as ProductType[]
        });
        if (res.status === 204) {
            toast({
                text: "Gespeichert",
                color: "green",
            });
            await queryClient.invalidateQueries({
                queryKey: appContextKey,
            });
            navigate("../")
        } else {
            toast({
                text: "Produkte konnten nicht gespeichert werden",
                color: "white",
                background: "red"
            });
        }
    }
    
    if (!data) {
        return <Loading />
    }
    
    const initialValues = data.reduce((acc, x) => {
        return {
            ...acc,
            [x.type]: false
        }
    }, {} as Record<ProductType, boolean>)
    return <>
        <h1>Produkte auswählen</h1>
        <h4>Wähle deine gewünschten Prdukte aus:</h4>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form className="grid grid-cols-2 gap-4 text-center p-4">
                {data.map(x => (
                    <CheckboxField name={x.type} label={x.type} className="bg-amber-500 cursor-pointer rounded-2xl" /> 
                ))}
                <SubmitButton>Speichern</SubmitButton>
            </Form>
        </Formik>
    </>
}