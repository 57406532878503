import {createContext, FC, ReactNode, useContext, useMemo} from "react";
import {useQuery} from "@tanstack/react-query";
import {api} from "../../api/api.ts";
import {ContextContractor} from "../../api/api_gen";

type LoadingState = {
    setupStep: "loading"
}
type DefineContractorStep = {
    setupStep: "defineContractor"
}
type ChooseProductsStep = {
    contractor: ContextContractor
    setupStep: "chooseProducts"
}
type SetupProductsStep = {
    contractor: ContextContractor
    setupStep: "setupProducts"
    resourceManagementStep: SetupResourceManagementInitStep | SetupResourceManagementFinshedStep
    portalSetup: SetupPortalRegistrationInitStep | SetupPortalRegistrationFinishedStep
}
type SetupFinishedStep = {
    contractor: ContextContractor
    setupStep: "setupFinished"
}

type SetupResourceManagementInitStep = {
    type: "setupResourceManagementInit"
}
type SetupResourceManagementFinshedStep = {
    type: "setupResourceManagementFinished"
}
type SetupPortalRegistrationInitStep = {
    type: "setupPortalRegistrationInit"
}
type SetupPortalRegistrationFinishedStep = {
    type: "setupPortalRegistrationFinished"
}

export type AppSetupState = LoadingState | DefineContractorStep | ChooseProductsStep | SetupProductsStep | SetupFinishedStep

/*interface AppContextState {
    appState: AppSetupState    
}*/

const AppContextContext = createContext<AppSetupState | undefined>(undefined);

export const appContextKey = ["appContext"]
export const AppContextProvider: FC<{children: ReactNode}> = ({children}) => {
    const {data} = useQuery({
        queryFn: () => api.getContext().then(x => x.data),
        queryKey: appContextKey
    })
    const state = useMemo<AppSetupState>(() => {
        if (!data) {
            return {
                setupStep: "loading"
            }
        }
        if (!data.hasDefinedContractor) {
            return {
                setupStep: "defineContractor"
            }
        }
        if (data.products.length === 0) {
            return {
                setupStep: "chooseProducts",
                contractor: data.contractor as ContextContractor
            } 
        }
        return {
            setupStep: "setupFinished",
            contractor: data.contractor as ContextContractor
        }
    }, [data])

    return (
        <AppContextContext.Provider value={state}>
            {children}
        </AppContextContext.Provider>
    );
}

export const useAppContext = () => {
    const ctx = useContext(AppContextContext);

    if (ctx === undefined) {
        throw new Error("useAppContext needs to be wrapped inside a AppContextProvider.");
    }

    return ctx;
};

export const isChooseProductsState = (state: AppSetupState): state is ChooseProductsStep => {
    return state.setupStep === "chooseProducts"
}
export const isDefineContractorState = (state: AppSetupState): state is DefineContractorStep => {
    return state.setupStep === "defineContractor"
}
export const isLoadingState = (state: AppSetupState): state is LoadingState => {
    return state.setupStep === "loading"
}
export const isFinishedState = (state: AppSetupState): state is SetupFinishedStep => {
    return state.setupStep === "setupFinished"
}