import {AdminApi} from "./api_gen";
import axios, {AxiosError, AxiosResponse} from "axios";
import {useEffect} from "react";
import {useAuth} from "../components/auth/AuthProvider.tsx";
import {useToasts} from "../components/toast/hooks.ts";

const axiosInstance = axios.create();

export const api = new AdminApi(undefined, "", axiosInstance);

const noop = (response: AxiosResponse) => response;

export const useApiInterceptor = () => {
    const { refresh } = useAuth();
    const {toast} = useToasts();
    
    useEffect(() => {
        const refreshInterceptor = async (error: AxiosError) => {
            const status = error.response ? error.response.status : null;
            const errorMessages = (
                Object.values(error.response ? (error.response.data as any).errors || {} : {})
                    .flat()
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    }) as string[]
            );
            if (status === 401) {
                await refresh();
                if (error.config) {
                    return axios.request(error.config);
                }
            }
          
            if (errorMessages.length > 0) {
                toast({
                    text: errorMessages.join(", "),
                    color: "red",
                });
            } else if (status !== 404) {
                toast({
                    text: "An error has occurred",
                    color: "red",
                });
            }
            return Promise.resolve(error);
        };

        const interceptor = axiosInstance.interceptors.response.use(noop, refreshInterceptor);

        return () => axiosInstance.interceptors.response.eject(interceptor);
    }, []);

    return api;
};
