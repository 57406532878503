import { NavLink } from 'react-router-dom';

export const Sidebar = () => {
    const menuItems = [
        { name: 'Dashboard', path: '/' },
        { name: 'Portal Registrierung', path: 'portal-registration' },
    ];

    return (
        <div className="h-screen w-64 bg-gray-800 text-white flex flex-col">
            <div className="text-center py-4 font-bold text-lg border-b border-gray-700">
                Admin Panel
            </div>
            <nav className="mt-4 flex flex-col space-y-2">
                {menuItems.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.path}
                        className={({ isActive }) =>
                            `block px-4 py-2 text-sm rounded-lg ${
                                isActive ? 'bg-gray-700' : 'hover:bg-gray-600'
                            }`
                        }
                    >
                        {item.name}
                    </NavLink>
                ))}
            </nav>
        </div>
    );
};