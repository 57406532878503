import {FC, useState} from "react";
import {
    flip,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions
} from "@floating-ui/react";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "./auth/AuthProvider.tsx";

export const ProfileDropdown: FC = ()=> {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const {logout} = useAuth()
    // Floating UI hooks for positioning
    const { x, y, refs, strategy, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(8), flip(), shift()],
    });

    // useClick hook to handle outside click
    const click = useClick(context);
    const dismiss = useDismiss(context);

    // Integrate interactions
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);
    const toggleDropdown = () => setIsOpen((prev) => !prev);
    const onLogoutClick = async () => {
        await logout();
        navigate("/");
    }
    return (
        <div className="relative">
            <button
                ref={refs.setReference}
                {...getReferenceProps()}
                onClick={toggleDropdown}
                className="w-10 h-10 rounded-full bg-blue-600 text-white flex items-center justify-center focus:outline-none"
            >
                U
            </button>

            {/* Floating Portal for Dropdown */}
            {isOpen && (
                <FloatingPortal>
                    <div
                        ref={refs.setFloating}
                        {...getFloatingProps()}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                        }}
                        className="w-48 bg-white rounded-lg shadow-lg py-2"
                    >
                        <Link
                            to="/profile"
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                            Profile
                        </Link>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-gray-700 text-left hover:bg-gray-100"
                            onClick={onLogoutClick}
                        >
                            Logout
                        </button>
                    </div>
                </FloatingPortal>
            )}
        </div>
    );
}
