import {FC} from "react";
import {useQuery} from "@tanstack/react-query";
import {api} from "../../../api/api.ts";
import {Form, Formik} from "formik";
import {TextField} from "../../../components/form/TextField.tsx";
import {PortalRegistrationRequest} from "../../../api/api_gen";
import {useToast} from "../../../components/toast/hooks.ts";
import {SubmitButton, TextButton} from "digitallife-react";
import {Loading} from "../../../components/Loading.tsx";

interface FormState extends PortalRegistrationRequest {
    
}
const emptyInitialValues: FormState = {
    imageUrl: "",
    values: {
        homepage: "",
        email: "",
        phone: "",
        address: {
            street: "",
            postcode: "",
            city: "",
            country: ""
        }
    }
}
export const PortalRegistrationPage: FC = () => {
    const {data, isLoading, refetch} = useQuery({
        queryKey: ["portal-entry"],
        queryFn: () => api.getPortalRegistration().then(x => x.data ?? null),
    })
    const {toast} = useToast()
    
    const onSubmit = async (values: FormState) => {
        const res = await api.setPortalRegistration(values);
        if (res.status === 204) {
            refetch();
            toast({
                text: "Gespeichert",
                color: "green",
            });
        }
    }
    if (isLoading) {
        return <Loading />
    }
    const initialValues: FormState = data ?? emptyInitialValues
    return <div className="flex justify-center max-w-lg flex-col mx-auto">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form className="">
                {data?.imageUrl && <img src={data.imageUrl} width="100%" height="100%" />}
                <TextField name="imageUrl" label="Bild" />
                <TextField name="values.homepage" label="Webseite" />
                <TextField name="values.email" label="E-Mail" />
                <TextField name="values.phone" label="Telefonnummer" />
                <div className="flex justify-end space-x-buttons">
                    <TextButton type={"button"}>Zurück</TextButton>
                    <SubmitButton>Speichern</SubmitButton>
                </div>
            </Form>
        </Formik>
    </div>;
}