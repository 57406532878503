import {DetailedHTMLProps, FC, InputHTMLAttributes} from "react";
import {useField} from "formik";

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
}
export const CheckboxField: FC<Props> = ({name, label, className, ...props}) => {
    const [inputProps, meta] = useField<string>(name);

    return (
        <div className={`text-field-wrapper ${className || ""}`}>
            {label && <label htmlFor={name} className="mb-4">{label}</label>} 
            <input
                {...props}
                {...inputProps}
                id={name}
                name={name}
                type={"checkbox"}
                className="text-field-input"
            />
            <span className="text-red-500 text-sm mt-1">{meta.touched && meta.error}</span>
        </div>
    );
}